<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h3 class="mb-15">{{$t('auth.resetPassword.title')}}</h3>
				<!-- <h5 class="font-regular text-muted">{{$t('auth.resetPassword.description')}}</h5> -->

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-5" :label="$t('auth.login.password.placeHolder')" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message:  $t('auth.login.password.required') }] },
						]" type="password" :placeholder="$t('auth.login.password.placeHolder')" />
					</a-form-item>
					<a-form-item class="mb-5" :label="$t('account.password.rePassword')" :colon="false">
						<a-input
							v-decorator="[
							'rePassword',
							{ rules: [{ required: true, message: 'Please input your Password!' }] },
							]"
							type="password"
							:placeholder="$t('account.password.rePassword')"
						>
						</a-input>
					</a-form-item>
					<a-form-item>
						<a-button :loading="loading" type="primary" block html-type="submit" class="login-form-button">
							{{$t('auth.resetPassword.btn')}}
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="/images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
	import authService from '@/services/auth'
	import { notification } from 'ant-design-vue';

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" 
				email: '',
				password : '',
				rememberMe: true,
				loading: false
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				this.loading = true
				e.preventDefault();
				this.form.validateFields(async (err, values)=> {
					if ( !err ) {
						let formData = new FormData();
						formData.append('password', values.password);
						formData.append('rePassword', values.rePassword);

						const res = await authService.resetPassword(this.$route.params.token,formData)
						if(res.status == 1){
							this.$router.push({ name: 'signIn'})	
						}else{
							notification.error({
								message: res.message
							});
						}
					}
					this.loading = false
				});
			},
		},
	})
</script>